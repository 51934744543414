// material-ui
//import logo from 'assets/images/ECAP_Logo_SVG.svg';

//Georgia Demo
//import logo from 'assets/images/georgia_logo.png'

//FFP Demo
//import logo from 'assets/images/FFP_SVG_BLUE.png';

//DCCCA logo
import logo from 'assets/images/DCCCA-logo-3.png';
import useAppSettings from 'hooks/useAppSettings';
import useAuth from 'hooks/useAuth';
// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const { appSettings, setAppSettings } = useAppSettings();
    const { auth,  } = useAuth();

    return (
       
        /* <img src={logo} alt="ECAP" width='90'/>*/
        <img src={`${auth?.themeJson?.Logo || appSettings.UILOGOIMAGENAME || ''}`} alt="ECAP" height={auth?.themeJson?.EcapLogoHeight ||appSettings.UILOGOIMAGEHEIGHT || 50} width={auth?.themeJson?.EcapLogoWidth ||appSettings.UILOGOIMAGEWIDTH || '50'} />
    );
};

export default Logo;
