import ReactDOM from 'react-dom';

// third party
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from 'react-redux';

// project imports
import * as serviceWorker from 'serviceWorker';
import App from 'App';
import { store } from 'store';
import { AuthProvider } from 'context/AuthProvider';
// style + assets
import 'assets/scss/style.scss';
import { SnackbarProvider } from 'notistack';
import { AuthConfigProvider } from 'context/AuthConfigProvider';

// License
import { LicenseInfo } from '@mui/x-license-pro'
import { AppSettingsProvider } from 'context/AppSettingsProvider';

LicenseInfo.setLicenseKey('c24f61580cb3d432965944ba131637b3Tz00NTQ4MixFPTE2ODY0OTcyNTg3NzAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');


// ==============================|| REACT DOM RENDER  ||============================== //

ReactDOM.render(
    <Provider store={store}>        
        <BrowserRouter>
            <SnackbarProvider
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                maxSnack={ 3 }
            >
                <AuthConfigProvider>
                    <AuthProvider>
                    <AppSettingsProvider>
                        <Routes>
                            <Route path="/*" element={<App />} />
                        </Routes>
                    </AppSettingsProvider>
                    </AuthProvider>
                </AuthConfigProvider>
            </SnackbarProvider>
        </BrowserRouter>               
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
