import { useSnackbar } from 'notistack';
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import React, {Fragment, useCallback, useEffect, useState} from "react";
import { render } from 'react-dom';


const useEcapSnackNotification = () => {
    const [conf, setConf] = useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const action = key => (
        <Fragment>
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <CloseIcon />
            </IconButton>
        </Fragment>
    );
    useEffect(()=>{
        if(conf?.msg){
            let variant = 'info';
            if(conf.variant){
                variant = conf.variant;
            }
            enqueueSnackbar(conf.msg, {
                variant: variant,
                autoHideDuration: conf.autoHideDuration || 5000,
                action
            });
        }
    },[conf]);
    return [conf, setConf];
};

export function useEcapSnackNotificationHTML() {
  const [conf, setConf] = useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [snackKey, setKey] = useState(0);

  const action = useCallback(() => {
    return <Fragment>
      <IconButton onClick={() => { closeSnackbar() }}>
        <CloseIcon />
      </IconButton>
    </Fragment>
  }, [closeSnackbar])

  const setSnackbar = useCallback((configuration) => {
    setConf(configuration);
  }, [])

  useEffect(() => {
    if (!conf.html) return;

    const key = enqueueSnackbar(conf.html, {
      variant: conf.variant,
      autoHideDuration: conf.autoHideDuration || 5000,
      style: conf.style,
      action: action,
    })

    setKey(key);
  }, [conf, enqueueSnackbar, action]);

  return { setSnackbar }
}

export default useEcapSnackNotification;