import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const ForgotPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ForgotPassword3')));
const ChangePassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ChangePassword3')));
const ResetPassword3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/ResetPassword3')));
const AddNewUser = Loadable(lazy(() => import('views/pages/authentication/auth-forms/AddNewUser')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/login',
            element: <AuthLogin3 />
        },
        {
            path: '/ForgotPassword',
            element: <ForgotPassword3 />

        },
        {
            path: '/ChangePassword',
            element: <ChangePassword3 />
        },
        {
            path: '/ResetPassword',
            element: <ResetPassword3 />
        },
        {
            path: '/AddNewUser',
            element: <AddNewUser />
        }
    ]
};

export default AuthenticationRoutes;
