// assets
import { IconKey } from '@tabler/icons';
import { ScopeConstants } from 'services/constants/scope-constants';

// constant
const icons = {
    IconKey
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = [
    {
       id: 'view-dashboard',
       title: 'Dashboard',
       url: '/',
       ecap: true,
       ffp: false,
       scope: { anyOf: [ScopeConstants.DASHBOARD_ASSIGNED_CASES, ScopeConstants.DASHBOARD_DATA_ALERTS, ScopeConstants.DASHBOARD_OTHER] },
       code: 'DASHBOARD'
    },
    {
        id: 'view-referrals',
        title: 'Placement Referrals',
        url: '/Placement/Referrals',
        scope: { anyOf: [ScopeConstants.LISTREFERRAL] },
        ecap: false,
        ffp: true,
        code: 'REFERRALS'
    },    
    {
       id: 'pages-awaiting-placement',
       title: 'Clients Awaiting Placement',
       url: '/AwaitingPlacement',
       scope: { anyOf: ['ReadIntake', 'ReadRemovedIntake'] },
       ecap: true,
       ffp: false,
       code: 'CAP'
    },
    {
       id: 'pages-reports',
       title: 'Reports',
       url: '/Reports',
       scope: { anyOf: ['RptClCallLog', 'RptProvCallLog', 'RptPlmntsByDt', 'RptPlReqs', 'RptRemovedPlReqs', 'RptPlMatchAudit', 'RptProvProf', 'RptClntAssess'] },
       ecap: true,
       ffp: false,
       code: 'REPORTS'
    },
    {
        id: 'pages-admin',
        title: 'Admin',
        url: '/Admin',
        scope: { anyOf: ['ListUsers', 'ListAgencies', 'DataAlertsDetails', ScopeConstants.FFP_GETALLREGISTRATIONS] },
        ecap: true,
        ffp: true,
        code: 'ADMIN'
    }
    ,
    {
       id: 'pages-search',
       title: 'Search',
       scope: { anyOf: [ ScopeConstants.SEARCHCLIENT, ScopeConstants.SEARCHPROVIDER] },
       url: '/Search',
       ecap: true,
       ffp: false,
       code: 'SEARCH'
    },
    {
        id: 'pages-support',
        title: 'support',
        url: '/support',
        ecap: true,
        ffp: true,
        code: 'SUPPORT'
    }
]

export default pages;
