import axios from 'axios';
import useAxios, { makeUseAxios } from 'axios-hooks'
import useAuth from 'hooks/useAuth';
import config from '../config'

const authzAxiosInst = axios.create({ baseURL: config.authZBaseApiUrl });
const useAuthZAxios = () => {
    const { auth } = useAuth();
    
    // Add a request interceptor
    authzAxiosInst.interceptors.request.use(function (config) {
        // Do something before request is sent
        if(!config.headers.common['Content-Type']) {
            config.headers.common['Content-Type'] = 'application/json';
        }
        if(auth.authN_IdToken) {
            config.headers.common['Authorization'] = `Bearer ${auth.authN_IdToken}`;
        }
        if(auth.authZ_AccessToken) {
            config.headers.common['JAuthorization'] = `Bearer ${auth.authZ_AccessToken}`;
        }
        
        return config;
    }, function (error) {
        // Do something with request error
        return Promise.reject(error);
    });

    // Add a response interceptor
    authzAxiosInst.interceptors.response.use(function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    }, async (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error

        return Promise.reject(error);
    });
    
    return makeUseAxios({
      axios: authzAxiosInst
    });
}

export default useAuthZAxios;