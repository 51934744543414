import { Box, Button, CircularProgress, Divider, FormHelperText, Grid, Link, Typography } from "@mui/material"
import { DataGridPremium, GridActionsCellItem, GridColDef, GridToolbar } from '@mui/x-data-grid-premium';
import { ArrowDropDown, Edit, OpenInNew } from '@mui/icons-material';
import useECAPAxios from "utils/ecapAxios";
import { useEffect, useState } from "react";
import { useEcapSnackNotificationHTML } from "hooks/useEcapSnackNotification";
import ConfirmDialog from "ui-component/extended/ConfirmDialog";
import MainCard from "ui-component/cards/MainCard";
import { useParams } from "react-router";
import FilePresentIcon from '@mui/icons-material/FilePresent';
import { FastField, Form, Formik } from "formik";
import * as Yup from 'yup';
import { LoadingButton } from "@mui/lab";
import { FormMenuMultipleField } from "ui-component/extended/FormMenuMultipleField";
import { shouldComponentUpdate } from "ui-component/extended/FormMenuField";
import { handleResponse } from "utils/common-helpers";
import { ScopeConstants } from "services/constants/scope-constants";
import useAuth from "hooks/useAuth";
import { couldShow } from "AuthNZ/showForPermission";
import themeEcap from "themes/ecap";
import { styled, useTheme } from '@mui/material/styles';

const SafeSummaryDetail = (props) => {
    const useAxiosInstance = useECAPAxios();
    let { safeSummaryId } = useParams();
    const theme = useTheme();

    if(!safeSummaryId){
        safeSummaryId = props.safeSummaryId;
    }
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const getAttachmentUrl = `ffp/api/placement-referral/attachment/`;
    const [{loading: attachmentFetchInProgress}, getAttachmentApi] = useAxiosInstance(getAttachmentUrl, { manual: true });
    const [{ loading: getFamiliesInProgress }, getFamiliesapi] = useAxiosInstance(
        {
            url: `api/family/all/by-agency`,
            method: 'GET',
        }, { manual: true });
    const [{ loading: sendToAdditinalFamiliesInProgress }, sendToAdditinalFamiliesApi] = useAxiosInstance(
        {
            url: `ffp/api/placement-referral/send-to-additional-families`,
            method: 'POST',
        }, { manual: true });
    const [{loading: getSafeSummaryDetailsInProgress}, getSafeSummaryDetailsApi] = useAxiosInstance(`ffp/api/placement-referral/safe-summary/${safeSummaryId}`, { manual: true });
    const [confirmDialog, setConfirmDialog] = useState<any>({ isOpen: false, msg: '', title: '' });
    const [safeSummaryDetails, setSafeSummaryDetails] = useState<any>({});
    const [downloadIdInProgress, setDownloadIdInProgress] = useState<any>(null);
    const [detailsHasError, setDetailsHasError] = useState(null)
    const [families, setFamilies] = useState([]);
    const [allFamilies, setAllFamilies] = useState([]);

    const {auth} = useAuth();
    const hasSaveSafeSummaryAccess = couldShow(auth, ScopeConstants.SAVEREFERRAL);

    useEffect(() => {
        getSafeSummaryDetailsApi()
        .then((resp) => {
            if(resp.data.isInErrorState) {
                setSnackbar({
                    html: `Error getting safe summary details. ${resp.data.validationMessages.map(x => x.message).join("<br />")}`,
                    variant: "error",
                  })
                  setDetailsHasError(true);
            }
            else{
                setSafeSummaryDetails(resp.data.data);
                setDetailsHasError(null);
            }
        })
        .catch(err => {
            setDetailsHasError(true);
            setSnackbar({
                html: `Error getting safe summary details.`,
                variant: "error",
              })
        })
    }, [])

    useEffect(() => {
        var familiesAlreadySent = (safeSummaryDetails && safeSummaryDetails.length && safeSummaryDetails[0].FamilyResponses && safeSummaryDetails[0].FamilyResponses.length)
        ? safeSummaryDetails[0].FamilyResponses.filter(x => x.familyId).map(x => x.familyId?.toString())
        : [];
        var familiesToSend = allFamilies.filter(x => !familiesAlreadySent.includes(x.code));
        setFamilies(familiesToSend);
    }, [allFamilies, safeSummaryDetails])
    
    useEffect(() => {
        if(hasSaveSafeSummaryAccess) {
            getFamiliesapi()
            .then(resp => {
                setAllFamilies(resp.data.map(x => { return { code: x.familyId.toString(), value: x.familyName } }))
            })
            .catch(err => {
                setSnackbar({
                    html: 'Error fetching families. ' + err.message,
                    variant: "error",
                })
            })
        }
    }, [])

    const downloadAttachment = (attachmentId, fileName) => {
        if(downloadIdInProgress) {
            setSnackbar({
                html: `Another download in progress. Please try after some time.`,
                variant: "error",
              })
            return;
        }
        setDownloadIdInProgress(attachmentId);
        getAttachmentApi({url: getAttachmentUrl + attachmentId, headers: {}, responseType: 'blob'})
        .then((resp => {
            const fileURL = URL.createObjectURL(resp.data);
            // window.open(fileURL)
            var link = document.createElement('a');
            link.href = fileURL;
            link.setAttribute('download', `${fileName}`);
            // link.setAttribute('target','_blank');
            document.body.appendChild(link);    
            link.click();
        }))
        .catch(err => {
            setSnackbar({
                html: `Error downloading file ${fileName}.`,
                variant: "error",
              })
        })
        .finally(() => {
            setDownloadIdInProgress(null);
        })
    }

    

    const sendToAdditinalFamilies = (values) => {
        sendToAdditinalFamiliesApi({data: {families: (values.families || []).map(x => { return { FamilyId: x.id } }), safeSummaryId: props.safeSummaryId}})
        .then(resp => {
            handleResponse(resp, () => {
                if(props.onAddAdditionalFamiliesSuccess) {
                    props.onAddAdditionalFamiliesSuccess();
                }
            }, setSnackbar, 'Successfully sent Safe summary to additional families.', null)
        })
        .catch(err => {
            setSnackbar({
                html: `Error saving additional families to this saf summary.`,
                variant: "error",
            })
        })
    }

    const TextWithLabel = ({label, text}) => {
        return (<Grid container sx={{p: 1}}>
                <Grid xs={6} sm={6} md={6} lg={2} sx={{fontWeight: 'bold'}}>{label}:</Grid>
                <Grid xs={6} sm={6} md={6} lg={10}>{text}</Grid>
        </Grid>);
    }

    const familyRespColumns = [
        {
            field: 'familyName',
            headerName: 'Provider',            
            flex: 1,
        },
        {
            field: 'agency',
            headerName: 'Agency',
            flex: 1,
        }, 
        {
            field: 'sentDate',
            headerName: 'Sent',
            flex: 1,
        },  
        {
            field: 'response',
            headerName: 'Response',
            renderCell: (params: GridRenderCellParams) => {
                const color = params.value == "Interested" ? "green" : (params.value == "Possibly Interested" ? "orange" : "black" );
                return (<h3 style={{ color: color }}> {params.value} </h3>);
            },
            flex: 1
        },
        {
            field: 'responseTime',
            headerName: 'Response Time',        
            flex: 1,
        },
    ]
    

    return ( 
    <MainCard
        title=""
        sx={{ m: 1, p: 3, '&.MuiPaper-root': {
            borderTopColor: `grey !important`
        } }}
    >
        <Grid>
            {getSafeSummaryDetailsInProgress
                ? <CircularProgress />
                : detailsHasError 
                    ? <div style={{color: 'red'}}>Error fetching safe summary details</div>
                    : <div style={{display: 'flex', flexDirection: 'column', minHeight: 500 }}>
                        {(safeSummaryDetails && safeSummaryDetails.length) 
                            ? <>{safeSummaryDetails.map(safeSummaryDetail => (<Grid xs={12} md={12} lg={12}><Box sx={{flexGrow: 1 }}>
                                <Divider sx={{height: 10, padding: 2}} ><Typography variant="h3">{safeSummaryDetail?.clientName} - Safe Summary</Typography></Divider>
                        
                            <Box sx={{flexGrow: 1 }}>
                                <TextWithLabel label="Date of Safe Summary" text={safeSummaryDetail?.ReferralDate} />
                                <TextWithLabel label="Safe Summary Id" text={safeSummaryDetail?.ReferralId} />
                                <TextWithLabel label="Placement Type" text={safeSummaryDetail?.placementtype} />
                                <TextWithLabel label="Age" text={safeSummaryDetail?.age} />
                                <TextWithLabel label="Gender" text={safeSummaryDetail?.gender} />
                                <TextWithLabel label="Ethnicity" text={safeSummaryDetail?.ethnicity} />
                                <TextWithLabel label="Race" text={safeSummaryDetail?.race} />
                                <TextWithLabel label="Case Plan Goal" text={safeSummaryDetail?.casePlanGoal} />
                                <TextWithLabel label="Level of Care" text={safeSummaryDetail?.levelOfCare} />
                                <TextWithLabel label="CMP" text={safeSummaryDetail?.cmp} />
                                <TextWithLabel label="Date of Referral" text={safeSummaryDetail?.refDate} />
                                <TextWithLabel label="Date Placement Needed" text={safeSummaryDetail?.placementNeededDate} />
                                <TextWithLabel label="Date Client Entered Care" text={safeSummaryDetail?.childEntercCareDt} />
                                <TextWithLabel label="Child's Current County" text={safeSummaryDetail?.county} />
                                <TextWithLabel label="Behaviors & Characteristics" text={safeSummaryDetail?.behaviors} />
                                <TextWithLabel label="Additional Referral Notes" text={safeSummaryDetail?.additionalRefNotes} />
                            </Box>
                            <Box sx={{flexGrow: 1 }}>
                                <h2 style={{color: theme.ecap.primary}}>Attachments Sent with Safe Summary</h2>
                                <div style={{padding: 1}}>
                                    {safeSummaryDetail?.FamilyAttachments?.length
                                        ? safeSummaryDetail?.FamilyAttachments?.map(att => {
                                                return (
                                                    <Grid sx={{padding: 1, flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                                                        <h4 style={{paddingRight: 10, color: '#507282'}}>{att.attachmentName}</h4>
                                                        <div><FilePresentIcon onClick={(e) => {
                                                                downloadAttachment(att.id, att.attachmentName)
                                                            }}
                                                        /></div>
                                                        <div>
                                                            {downloadIdInProgress == att.id && <CircularProgress />}
                                                        </div>
                                                    </Grid>
                                                )
                                            })
                                        : "There are no attachments."
                                    }
                                </div>
                            </Box>
                            <Box sx={{flexGrow: 1 }}>
                            <h2 style={{color: theme.ecap.primary}}>Other Attachments - Not sent to providers</h2>
                                <div style={{padding: 1}}>
                                    {safeSummaryDetail?.WorkerAttachments?.length
                                        ?   safeSummaryDetail?.WorkerAttachments?.map(att => {
                                                return (
                                                    <Grid sx={{padding: 1, flexDirection: 'row', alignItems: 'center', display: 'flex'}}>
                                                        <h4 style={{paddingRight: 10, color: '#507282'}}>{att.attachmentName}</h4>
                                                        <div>
                                                            <FilePresentIcon onClick={(e) => {
                                                                    downloadAttachment(att.id, att.attachmentName)
                                                                }}
                                                            />
                                                        </div>
                                                        <div>
                                                            {downloadIdInProgress == att.id && <CircularProgress />}
                                                        </div>
                                                    </Grid>
                                                )
                                            })
                                        : "There are no attachments."
                                    }
                                </div>
                            </Box>
                            </Box></Grid>))}
                            <Box sx={{flexGrow: 1 }}>
                                <h2 style={{color: theme.ecap.primary}}>Safe Summary Sent</h2>
                                <div style={{ display: 'flex', height: 500}}>
                                    <Box sx={{flexGrow: 1 }}>
                                        {safeSummaryDetails[0].FamilyResponses?.length 
                                            ? <DataGridPremium
                                                    rows={safeSummaryDetails[0].FamilyResponses}
                                                    columns={familyRespColumns}
                                                    hideFooterRowCount                        
                                                    disableSelectionOnClick
                                                    sortable={false}
                                                    components={{ Toolbar: GridToolbar  }}
                                                    componentsProps={{
                                                        toolbar: {
                                                          showQuickFilter: true,
                                                          quickFilterProps: { debounceMs: 500 },
                                                        },
                                                      }}
                                                />
                                            : "No data"
                                        }
                                    </Box>
                                </div>
                            </Box>
                            {safeSummaryDetails && safeSummaryDetails.length && hasSaveSafeSummaryAccess && safeSummaryDetails[0].crBySameAgUser && safeSummaryDetails[0].IsActive && <Box>
                            <Formik
                                initialValues={{families: []}}
                                enableReinitialize
                                validationSchema={Yup.object().shape({
                                    families: Yup.array().min(1, "Safe summary should be asigned to atleast a family.").required("required")
                                })}
                                onSubmit={async (values, { setErrors, setStatus, setSubmitting, validateForm, isValid }) => {
                                    setSubmitting(false)
                                    sendToAdditinalFamilies(values);
                                    
                                }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, validateForm, submitCount, isValid, isSubmitting, touched, values, setTouched, setFieldValue }) => (
                                    <Form noValidate autoComplete="off" style={{ width: '100%' }}
                                        onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                                            validateForm().then((response: any) => {
                                                const parsedResponse = JSON.stringify(Object.values(response).join(` <br /> `));

                                                if (parsedResponse && parsedResponse.length > 2) {
                                                    setSnackbar({
                                                        html: "validation errors please recheck form",
                                                        variant: "error",
                                                    });
                                                    setTouched(setNestedObjectValues(response, true));
                                                }
                                            })


                                            handleSubmit(e);
                                        }}
                                    >
                                        <Grid container xs={12} sm={12} sx={{ alignItems: 'center', justifyContent: 'space-between', display: 'flex' }}>
                                            <Grid item xs={12} sm={12}> <h4>Send to additional providers</h4></Grid>
                                            <Grid item xs={12} sm={12}>
                                                <FastField
                                                    fullWidth
                                                    name="families"
                                                    label="Families"
                                                    required
                                                    menuList={families}
                                                    addSelectAll
                                                    component={FormMenuMultipleField}
                                                    loading={getFamiliesInProgress}
                                                    shouldUpdate={shouldComponentUpdate}
                                                />
                                            </Grid>
                                            {errors.submit && (
                                                <Box sx={{ mt: 3 }}>
                                                    <FormHelperText error>{errors.submit}</FormHelperText>
                                                </Box>
                                            )}

                                            <Box sx={{ mt: 2, width: '100%', display: 'inline-flex', justifyContent: 'space-between' }}>


                                                <Button
                                                    disableElevation
                                                    variant="contained"
                                                    color="secondary"
                                                    onClick={() =>  props.handleClose() }
                                                >
                                                    Close without Sending
                                                </Button>



                                                <LoadingButton
                                                    disableElevation
                                                    loading={sendToAdditinalFamiliesInProgress}
                                                    type="submit"
                                                    variant="contained"
                                                    color="primary"
                                                    disabled={sendToAdditinalFamiliesInProgress}
                                                >
                                                    Send
                                                </LoadingButton>

                                            </Box>
                                        </Grid>
                                    </Form>
                                    )}
                                </Formik>
                                </Box>}
                            </>
                            : <>No Details</>}
                    </div>
            }
        </Grid>
    </MainCard>
    )
}
export default SafeSummaryDetail