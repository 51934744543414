import { tokenParserInst } from "./TokenParser";

export const handleResponse = (response, onSuccess, setSnackbar, successMsg, onFail) => {
    if(response.data && response.data.isInErrorState) {
        setSnackbar({
          html: <div style={{whiteSpace: 'pre-line'}}>{response.data.validationMessages.map(x => x.message).join("\n")}</div>,
          variant: "error",
        })

        if(onFail && typeof onFail === 'function') {
          onFail()
        }
      }
      else{
        if(successMsg) {
          setSnackbar({
              html: successMsg,
              variant: "success",
            })
        }
        if(onSuccess) {
            onSuccess(response);
        }
      }
};

export const searchObject = (searchObj, searchText) => {
  if(!searchText) {
    return true;
  }
  for (var objPropName in searchObj) {
    var value = searchObj[objPropName];

    if (typeof value === 'object') {
        const innerObjSearchResult = searchObject(value, searchText);
        if(innerObjSearchResult) {return true};
    }

    if (value && value?.toString()?.toUpperCase()?.includes( searchText?.toUpperCase())) {
        return true;
    }

  }
}

export const getAddressByType = (clientLocations, typeId) => {
  var locations = [];
  (clientLocations || []).forEach(clientWithLocation => {
      var location = (clientWithLocation.locations || []).find(x => x.locationTypeId === typeId);
      if (location && location.latitude && location.longitude) {
          locations.push({ fullName: clientWithLocation.firstName + ' ' + clientWithLocation.lastName, location: location });
      }
  });
  return locations;
}

export const getLeadAgencyFlag = (setSnackbar, ecapWorkerToken) => {
  try{
    if(!ecapWorkerToken) {
      ecapWorkerToken = JSON.parse(localStorage.getItem("AuthDetails")).ecap_WorkerToken
    }
    var workerTokenInfoJson = tokenParserInst.parseJWTPayload(ecapWorkerToken);
    
    var isLeadAgencyUser = JSON.parse(workerTokenInfoJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]).IsLeadAgencyUser
    return isLeadAgencyUser;
}
catch(err) {
  if(setSnackbar) {
    setSnackbar({
        html: 'Error Finding user agency. ' + err.message,
        variant: "error",
    })
  }
  else{
    console.error(err);
  }
}
}

export const getAgencyId = (setSnackbar, ecapWorkerToken) => {
  try{
    if(!ecapWorkerToken) {
      ecapWorkerToken = JSON.parse(localStorage.getItem("AuthDetails")).ecap_WorkerToken
    }
    var workerTokenInfoJson = tokenParserInst.parseJWTPayload(ecapWorkerToken);
    
    var agencyId = JSON.parse(workerTokenInfoJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]).SelectedAgencyId
    return agencyId;
  }
  catch(err) {
    if(setSnackbar) {
      setSnackbar({
          html: 'Error Finding user agency. ' + err.message,
          variant: "error",
      })
    }
    else{
      console.error(err);
    }
  }
} 

export const getAgencyName = (setSnackbar, ecapWorkerToken) => {
  try{
    if(!ecapWorkerToken) {
      ecapWorkerToken = JSON.parse(localStorage.getItem("AuthDetails")).ecap_WorkerToken
    }
    var workerTokenInfoJson = tokenParserInst.parseJWTPayload(ecapWorkerToken);
    
    var agencyName = JSON.parse(workerTokenInfoJson["http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"]).SelectedAgencyName
    return agencyName;
  }
  catch(err) {
    if(setSnackbar) {
      setSnackbar({
          html: 'Error Finding user agency. ' + err.message,
          variant: "error",
      })
    }
    else{
      console.error(err);
    }
  }
} 
