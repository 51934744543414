class TokenParser {
    // Convert Payload from Base64-URL to JSON
    decodePayload(payload) {
        const cleanedPayload = payload.replace(/-/g, '+').replace(/_/g, '/');
        const decodedPayload = atob(cleanedPayload);
        const uriEncodedPayload = Array.from(decodedPayload).reduce((acc, char) => {
            const uriEncodedChar = (`00${char.charCodeAt(0).toString(16)}`).slice(-2);
            return `${acc}%${uriEncodedChar}`;
        }, '');
        const jsonPayload = decodeURIComponent(uriEncodedPayload);

        return JSON.parse(jsonPayload);
    }

    // Parse JWT Payload
    parseJWTPayload(token) {
        const [header, payload, signature] = token.split('.');
        const jsonPayload = this.decodePayload(payload);

        return jsonPayload;
    }

    // Parse JWT Header
    parseJWTHeader(token) {
        const [header, payload, signature] = token.split('.');
        const jsonHeader = this.decodePayload(header);

        return jsonHeader;
    }
    // Convert Hash to Base64-URL
    hashToBase64url(arrayBuffer) {
        const items = new Uint8Array(arrayBuffer);
        const stringifiedArrayHash = items.reduce((acc, i) => `${acc}${String.fromCharCode(i)}`, '');
        const decodedHash = btoa(stringifiedArrayHash);

        const base64URL = decodedHash.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        return base64URL;
    }
}

const tokenParserInst1 = new TokenParser();
export const tokenParserInst = tokenParserInst1;
