import { CircularProgress } from '@mui/material';
import { Auth } from 'aws-amplify';
import useAxios from 'axios-hooks';
import useAuthConfig from 'hooks/useAuthConfig';
import { useEcapSnackNotificationHTML } from 'hooks/useEcapSnackNotification';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import useECAPAxios from 'utils/ecapAxios';
import { parseAuthResponse } from 'views/pages/authentication/auth-forms/AuthLogin';
import config from '../config'
import useAuth from 'hooks/useAuth';
import axios from 'axios';
import pages from 'menu-items/pages';

const AppSettingsContext = createContext({});

export const AppSettingsProvider = ({ children }) => {
    const useAxiosInstance = useECAPAxios();
    const { setSnackbar } = useEcapSnackNotificationHTML();
    const [{ loading: custSettingsFetchInProgress }, getCustSettingsApi] = useAxios(config.baseApiUrl + `/api/metadata/wapp-settings`, { manual: true });
    const [loadComplete, setLoadComplete] = useState(false);
    const [appSettings, setAppSettings] = useState({a: 1});
    const navigate = useNavigate();
    const {auth} = useAuth();

    function setWebPageAppMetadata(resp) {
        const hasEcap = resp.data.data.HASECAP;
        const hasFfp = resp.data.data.HASFFP;
        document.title = resp.data.data.UIPAGETITLE || "ECAP";
        
        let descMeta = document.querySelector("meta[name='description']");
        let titleMeta = document.querySelector("meta[name='title']");
        let keywordsMeta = document.querySelector("meta[name='keywords']");
        
        let themeMeta = document.querySelector("meta[name='theme-color']");
        themeMeta.setAttribute("content", (resp.data.data.UITHEMECOLOR || "#2296f3"));
        
        let favIcon = document.querySelector("link[rel='icon']");
        if(resp.data.data.UIFAVICONNAME) {
           favIcon.setAttribute("href", (resp.data.data.UIFAVICONNAME || "favicon-dccca-32x32.png"));
        }
        if (hasEcap) {
            descMeta.setAttribute("content", 'ECAP (Every Child A Priority) is a research-backed system that enables users to make use of the ECAP Placement Matching Process, which helps agency staff make better placement and matching decisions for their children in care.');
            titleMeta.setAttribute("content", "ECAP");
            keywordsMeta.setAttribute("content", "ECAP, Every Child A Priority, research backed, placements, ");
        }
        else if (hasFfp) {
            descMeta.setAttribute("content", "Foster Family Portal is an application that allows foster parents to collaborate more closely with their agencies.");
            titleMeta.setAttribute("content", "Foster Family Portal");
            keywordsMeta.setAttribute("content", "FFP, Foster Family Portal, foster parents, placements, foster agencies");
        }
    }

    const loadAppSettings = () => {
        const agencyId = auth?.agencyId;
        getCustSettingsApi({url: config.baseApiUrl + `/api/metadata/wapp-settings${agencyId ? "?agencyId="+ agencyId : ''}`})
        .then(resp => {
            setAppSettings({...resp.data.data, loadAppSettings: loadAppSettings});

            setWebPageAppMetadata(resp);
            var hasAccess = pages.find(x => !x.anyOf || !x.anyOf.length || x.anyOf.some(y => auth.roles.includes(y)));
            var matchingPage = pages.find(x => window.location.pathname.toLowerCase() == x.url.toLowerCase());
            if(matchingPage) {
                if(matchingPage.ecap && matchingPage.ffp) {

                }
                else{
                    let isNotAccessible = false;
                    if((matchingPage.ecap && !resp.data.data.HASECAP)) {
                        isNotAccessible = true;
                    }
                    if((matchingPage.ffp && !resp.data.data.HASFFP)) {
                        isNotAccessible = true;
                    }

                    if(isNotAccessible) {
                        if(resp.data.data.DEFAULTHOMEPAGE) {
                            var indexOfQuestionMark = window.location.href.includes("?") ? window.location.href.indexOf('?') : 0;
                            
                            navigate(resp.data.data.DEFAULTHOMEPAGE + (indexOfQuestionMark ? window.location.href.slice(indexOfQuestionMark) : ""));
                        }
                    }
                }
            }
            
            
            setLoadComplete(true);

            
        })
        .catch(err => {
            if(!axios.isCancel(err)) {
                setSnackbar({
                    html: "Error getting customer settings.",
                    variant: "error",
                })
                setLoadComplete(true);
            }
        })
    }

    useEffect(() => {
        loadAppSettings();
    }, [auth])

    return !loadComplete 
            ? (<CircularProgress />)
            : (<AppSettingsContext.Provider value={{ appSettings, setAppSettings }} >
                {children}
              </AppSettingsContext.Provider>)
        
}

export default AppSettingsContext;