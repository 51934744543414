import useAuth from 'hooks/useAuth';
import React from 'react';

const ShowForPermissionComponent = (props) => {
    const {auth} = useAuth();

    const couldShow1 = couldShow(auth, props.permission)
    return couldShow1 ? props.children : props.showMessageIfNotApplicable ? (<div style={{color: 'red'}}>You are not authorized to view this.</div>) :  '';
};

export const couldShow = (auth, permission) => {
    return auth && auth.roles && auth.roles.length && auth.roles.includes(permission) && !auth?.agencyScopesToExclude?.includes(permission);
};

export const ShowForPermission = ShowForPermissionComponent;