export const ScopeConstants = {
    DASHBOARD_ASSIGNED_CASES: "AssignedCases",
    DASHBOARD_DATA_ALERTS: "DataAlertsCount",
    DASHBOARD_OTHER: "OtherDashboard",

    ADMIN_LIST_USERS: 'ListUsers',
    ADMIN_SAVE_USER: 'CreateUser',
    ADMIN_ENABLE_USER: 'EnableUser',
    ADMIN_DISABLE_USER: 'DisableUser',
    ADMIN_RESENDTEMPPWD_USER: 'ResendTempPassword',
    ADMIN_LIST_AGENCIES: 'ListAgencies',
    ADMIN_SAVE_AGENCY: 'CreateAgency',
    ADMIN_DATA_ALERTS: 'DataAlertsDetails',

    AWAITINGPLACEMENTLIST: 'ReadIntake',
    SAVEINTAKE: 'SaveIntake',
    RECENTLYREMOVEDAWAITINGPLACEMENTLIST: 'ReadRemovedIntake',
    ADDBACKTOLIST: 'AddBackRemovedIntake',
    REMOVEINTAKE: 'DeleteIntake',
    
    GETPLGROUP: 'GetPlGroup',
    SAVEPLGROUP: 'SavePlGroup',
    DELETEPLGROUP: 'DeletePlGroup',

    CLIENTREPORTS: 'ClientReports',
    PROVIDERREPORTS: 'ProviderReports',
    RPTCLCALLLOG: 'RptClCallLog', 
    RPTPROVCALLLOG: 'RptProvCallLog', 
    RPTPLMNTSBYDT: 'RptPlmntsByDt',
    RPTPLREQS: 'RptPlReqs',
    RPTREMOVEDPLREQS: 'RptRemovedPlReqs',
    RPTPLMATCHAUDIT: 'RptPlMatchAudit',
    RPTPROVPROF: 'RptProvProf',
    RPTCLNTASSESS: 'RptClntAssess',

    READPLACEMENT: 'ReadPlacement',
    SAVEPLACEMENT: 'SavePlacement',
    DELETEPLACEMENT: 'DeletePlacement',

    READCLIENT: 'ReadClient',
    SAVECLIENT: 'SaveClient',
    DELETECLIENT: 'DeleteClient',
    SEARCHCLIENT: 'SearchClient',
    CLIENTHISTORY: 'ClientHistory',

    READPROVIDER: 'ReadProvider',
    SAVEPROVIDER: 'SaveProvider',
    DELETEPROVIDER: 'DeleteProvider',
    SEARCHPROVIDER: 'SearchProvider',

    FFP_GETALLREGISTRATIONS: 'GetAllRegistrations',
    FFP_APPROVEREGISTRATION: 'ApproveRegistration',
    FFP_GETFAMILYSAFESUMMARIES: 'GetFamilyReferrals',
    FFP_GETFAMILYREQUIREMENTSLISTFORADMIN: 'GetFamilyRequirementsListForAdmin',
    FFP_SAVEFAMILYLICENSINGPERIODSLISTFORADMIN: 'SaveFamilyLicensingPeriodsListForAdmin',
    FFP_LINKFFPFAMILYTOECAPPROVIDER: 'MapFFPFamilyToECAPProvider',
    FFP_ASSIGNECAPWORKERTOFFPFAMILY: 'AssignECAPWorkerToFFPFamily',
    FFP_GETFOSTERFAMILYINFCONTACTS: 'GetFamilyContactsInfo',

    MODIFYASSESSMENTLOC: 'ModifyAssessmentLoc',

    GETFAMILYPROFILE: 'GetFamilyProfile',
    DELETEFAMILY: 'DeleteFamily',

    LISTREFERRAL: 'GetWorkerReferrals',
    SAVEREFERRAL: 'SendMatches',

    BESTMATCH: 'GetBMLProviderDetails',
    REVERSEMATCH: 'GetProvDetailsForRML',

    GETSERVICEMATCHCLIENT: 'GetServiceMatchClientDetails',

    SUPERADMIN_VIEWALLSPLASHES: 'GetAllWorkerSplashes',
    SUPERADMIN_SAVESPLASH: 'SaveWorkerSplash',
    SUPERADMIN_DELETESPLASH: 'DeleteWorkerSplash',
    SUPERADMIN_SAVECUSTSETTING: 'SaveCustomerSettingAdmin',
    SUPERADMIN_DELETECUSTSETTING: 'DeleteCustomerSettingAdmin'
}