import { Button, Checkbox, Grid, InputAdornment, FormControlLabel, Typography, Switch } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { Field, Form, Formik, FormikHelpers, FormikValues, FormikProps } from "formik";
import { FormTextField } from "ui-component/extended/FormTextField";
import * as yup from 'yup';
import SearchIcon from '@mui/icons-material/Search';
import { SearchFormAPI } from 'services/search/search-types';

import { useEffect, useState } from "react";
import ClientSearchResultsTable, { SearchResultItem } from "ui-component/tables/ClientSearchResults";
import useECAPAxios from 'utils/ecapAxios';
import { AxiosError, AxiosResponse } from "axios";
import { useEcapSnackNotificationHTML } from "hooks/useEcapSnackNotification";
import CustomDialog from "ui-component/extended/CustomDialog";
import ConfirmDialog from "ui-component/extended/ConfirmDialog";
import { couldShow, ShowForPermission } from "AuthNZ/showForPermission";
import useAuth from "hooks/useAuth";
import { ScopeConstants } from "services/constants/scope-constants";
import useAppSettings from "hooks/useAppSettings";
import SplashDetail from "./splash-detail";

export interface LatestSplashProps {
  
}

function LatestSplash(props: LatestSplashProps) {
  const { setSnackbar } = useEcapSnackNotificationHTML();
  const useAxiosInst = useECAPAxios();

  const [latestSplashData, setLatestSplashData] = useState(null);

  const [{ loading: splashLoading }, getLatestUnreadSplashApi] = useAxiosInst({ url: `api/splash/current`, method: 'GET' }, { manual: true });

  useEffect(() => {
    getLatestSplash();
  }, [])

  const getLatestSplash = () => {
    getLatestUnreadSplashApi()
    .then((response: AxiosResponse) => {
      if(response.data && response.data.isInErrorState) {
        setSnackbar({
          html: response.data.validationMessages.map(x => x.message).join("<br />"),
          variant: "error",
        })
      }
      else if (response.data && response.data && response.data.content) {
        setLatestSplashData(response.data);
      }
    }).catch((error: AxiosError) => {
      setSnackbar({
        html: "Error with getting latest splash",
        variant: "error",
      })
    })
  }
  
  return (
    <>
    <SplashDetail splashData={latestSplashData} showPrimAction={true} />
    </>
  );
}

export default LatestSplash;