import useAuthConfig from 'hooks/useAuthConfig';
import appConfig from './config';


const awsAuthConfig = {
    getAuthConfig : (authzConfig) => {
        return {
            Auth: {

                // REQUIRED - Amazon Cognito Region
                region: 'us-east-1',

                // OPTIONAL - Amazon Cognito User Pool ID
                userPoolId: authzConfig.authN.cognitoUserPoolId,

                // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
                userPoolWebClientId: authzConfig.authN.cognitoClientId,

                // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
                mandatorySignIn: false,

                // // OPTIONAL - Configuration for cookie storage
                // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
                // cookieStorage: {
                // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
                //     domain: '.yourdomain.com',
                // // OPTIONAL - Cookie path
                //     path: '/',
                // // OPTIONAL - Cookie expiration in days
                //     expires: 365,
                // // OPTIONAL - See: https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie/SameSite
                //     sameSite: "strict",
                // // OPTIONAL - Cookie secure flag
                // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
                //     secure: false
                // },

                storage: window.localStorage,
                
                // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
                authenticationFlowType: 'USER_PASSWORD_AUTH',

                // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
                clientMetadata: { myCustomKey: 'myCustomValue' },

                // OPTIONAL - Hosted UI configuration
                oauth: {
                    domain: authzConfig.authN.cognitoHostedDomain,
                    scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
                    redirectSignIn: authzConfig.authN.redirectUri,
                    redirectSignOut: authzConfig.authN.logoutUri,
                    responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
                }
            }
        }
    }
}

export default awsAuthConfig;