import { Auth } from 'aws-amplify';
import useAuthConfig from 'hooks/useAuthConfig';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { parseAuthResponse } from 'views/pages/authentication/auth-forms/AuthLogin';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const { authConfig, setAuthConfig } =  useAuthConfig();
    const navigate = useNavigate();
    
    
        // let authState = {};
        // if(authResp) {
        //     parseAuthResponse(authResp, authConfig).then((parsedResp) => {
        //         setAuth({ ...parsedResp });
        //     });
            
            
        //     // var pem = jwkToPem(jwk);
        //     // jwt.verify(token, pem, { algorithms: ['RS256'] }, function(err, decodedToken) {
        //     // });

        // }
     
        const authFromLocStor = localStorage.getItem("AuthDetails");
        let authResp = undefined;
        if(authFromLocStor) {
            try {
                authResp = JSON.parse(authFromLocStor);
                // setAuth({ ...authResp });
            } catch (error) {
                console.error('error reading auth resp from Local storage')
            }
        }

        const cognitoTokenRefresh = async () => {
            Auth.currentSession().then(session => {
                const refresh_token = session.getRefreshToken();
                session.refreshSession(refresh_token, (refErr, refSession) => {
                    if (refErr) {
                        throw refErr;
                    }
                    else{
                        //this provide new accessToken, IdToken, refreshToken
                        // you can add you code here once you get new accessToken, IdToken, refreshToken
                    }
                });
            })
        }

        if(authResp && authResp.statuses && authResp.statuses.length) {
            const authTokens = authResp.statuses.filter(authElSt => authElSt.tokenInfo);//.map(authElSt => authElSt.tokenInfo)
            if(authTokens && authTokens.length) {
                authTokens.forEach(authEl => {
                    const authElSt = authEl.tokenInfo
                    if(authElSt.expiresIn) {
                        if(new Date(authElSt.expiresIn * 1000) < new Date()) {
                            localStorage.removeItem("AuthDetails");
                            navigate('/login');
                        }
                    }
                    else{
                        navigate('/login');
                    }
                });
            }
            else{
                navigate('/login');
            }
        }
    useEffect(() => {
        
    }, [])
    const [auth, setAuth] = useState(authResp);

    return (
        <AuthContext.Provider value={{ auth, setAuth }} >
            {children}
        </AuthContext.Provider>
        )
}

export default AuthContext;