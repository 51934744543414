import React from 'react';

// material-ui
import {
    Box,
    Container,
    Grid,
    Typography,
    Button
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project imports
import CustomDialog from 'ui-component/extended/CustomDialog';
// import { useIntl } from 'react-intl';
import useAppSettings from 'hooks/useAppSettings';

// ==============================|| FOOTER / DIALOG POPUP ||============================== //


const Footer = () => {
    // const intl = useIntl();
    // const intl = {formatMessage : () => {}}
    const [openPolicy, setOpenPolicy] = React.useState(false);
    const [openAbout, setOpenAbout] = React.useState(false);
    const [openAboutFfp, setOpenAboutFfp] = React.useState(false);

    const handleOpenPolicy = () => setOpenPolicy(true);
    const handleClosePolicy = () => setOpenPolicy(false);

    const handleOpenAbout = () => setOpenAbout(true);
    const handleCloseAbout = () => setOpenAbout(false);
    const handleOpenAboutFFP = () => setOpenAboutFfp(true);
    const handleCloseFfp = () => setOpenAboutFfp(false);

    const theme = useTheme();
    const { appSettings } = useAppSettings();

    return (
        <>

            <Box bgcolor={theme.ecap.primary ?? theme.palette.primary.main} color="white">
                <Container maxWidth="xl">
                    <Grid container spacing={5}>
                        <Grid item xs={12} sm={3} md={2}>
                            <Box borderBottom={1} sx={{ mt: 2 }}>Help</Box>
                            <Box>
                                <Button link={true.toString()} onClick={handleOpenAbout} sx={{ color: 'white' }}>About ECAP</Button>
                            </Box>
                            <Box>
                                <Button link={true.toString()} onClick={handleOpenAboutFFP} sx={{ color: 'white' }}>About FFP</Button>
                            </Box>
                            <Box>
                                <Button link={true.toString()} onClick={handleOpenPolicy} sx={{ color: 'white' }}>Privacy Policy</Button>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8}></Grid>
                        <Grid item xs={12} sm={1}>
                        </Grid>
                    </Grid>
                    <Box textAlign="center" pt={{ xs: 5, sm: 5 }} pb={{ xs: 5, sm: 2 }}>
                        Foster Care Technologies &reg; {new Date().getFullYear()}
                    </Box>
                </Container>
            </Box>
            <CustomDialog title='Privacy Policy' isOpen={openPolicy} handleClose={handleClosePolicy}>
                <Typography>Foster Care Technologies uses customer data to improve the products and services offered. Data is never shared with third parties and all data entered into systems maintained by Foster Care Technologies is owned by the customers. Customers have the option to delete this data when they terminate their contracts for products or services with Foster Care Technologies, LLC. </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    How do we protect your information?
                </Typography>
                <Typography sx={{ mt: 2 }}>
                    We use regular Malware Scanning.
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.

                </Typography>
                <Typography sx={{ mt: 1 }}>
                    We implement a variety of security measures when a user enters, submits, or accesses their information to maintain the safety of your personal information.

                </Typography>
                <Typography sx={{ mt: 1 }}>
                    All secure transactions are processed through a gateway provider and are not stored or processed on our servers.
                </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    Do we use 'cookies'?
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    Yes. Cookies are small files that a site or its service provider transfers to your computer's hard drive through your Web browser (if you allow) that enables the site's or service provider's systems to recognize your browser and capture and remember certain information. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.

                </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    We use cookies to:
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browser's Help Menu to learn the correct way to modify your cookies.

                </Typography>
                <Typography sx={{ mt: 1 }}>
                    If you turn cookies off, Some of the features that make your site experience more efficient may not function properly.It won't affect the user's experience that make your site experience more efficient and may not function properly.

                </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    <b>Third-party disclosure</b>
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.
                </Typography>
            </CustomDialog>
            <CustomDialog title={"About FFP"} isOpen={openAboutFfp} handleClose={handleCloseFfp}>

                <Typography sx={{ mt: 2 }}>
                    Foster Family Portal, a cutting-edge mobile application designed to seamlessly connect child welfare agencies with their dedicated foster families. With its top-notch user interface, intuitive collaboration tools, and straightforward integration capabilities, collaboration has never felt more natural or efficient.
                </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    Benefits for Foster Families:
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <ul>
                        <li>Instant Notifications: Get immediate alerts for potential matches directly on your mobile device.</li>
                        <li>Quick Response System: React to research-informed matches from your agency in real time.</li>
                        <li>Central Resource Hub: Easily access important links and contact details for your agency and partner organizations.</li>
                    </ul>
                </Typography>
                <Typography sx={{ mt: 2, fontWeight: 'bold' }}>
                    Benefits for Agencies:
                </Typography>
                <Typography sx={{ mt: 1 }}>
                    <ul>
                        <li>Smart Summaries with AI: Our advanced OCR technology converts your PDF referral documents into concise, secure summaries ready to be dispatched to prospective families.</li>
                        <li>Engagement Boost: Actively notify foster families of emerging potential matches, ensuring they remain involved in the matching process.</li>
                        <li>Resource Provision: Offer foster families essential links, resources, and support they need.</li>
                        <li>Integration with ECAP: Optionally sync and send potential matches from ECAP effortlessly.</li>
                    </ul>
                </Typography>
            </CustomDialog>
            
            <CustomDialog title={"About ECAP"} isOpen={openAbout} handleClose={handleCloseAbout}>

                <Typography sx={{ mt: 2 }}>
                    Every child deserves a stable, loving home. That’s why we created ECAP – a unique approach to foster care placements that prioritizes the child’s needs and ensures lasting, positive outcomes.
                </Typography>
                <Typography sx={{ mt: 2 }}>
                Using cutting-edge algorithms, a deep understanding of child welfare, and the power of community, ECAP is changing the foster care landscape one child at a time.
                </Typography>
            </CustomDialog>
        </>
    );
};

export default Footer;
