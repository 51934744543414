import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes, { CommonRoutes, EcapRoutes, FfpRoutes, getMainRoutesBy } from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import config from 'config';
import useAppSettings from 'hooks/useAppSettings';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    
    const { appSettings, } = useAppSettings();
    const allRoutes = getMainRoutesBy(appSettings);

    // if(appSettings.HASFFP) {
    //     allRoutes.push(EcapRoutes);
    // }
    // if(appSettings.HASECAP) {
    //     allRoutes.push(FfpRoutes);
    // }
    // allRoutes.push(CommonRoutes);
    
    // allRoutes.push(AuthenticationRoutes);

    // return useRoutes([allRoutes], config.basename);
    return useRoutes([allRoutes, AuthenticationRoutes], config.basename);
}
