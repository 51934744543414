import { Auth } from 'aws-amplify';
import useAuthConfig from 'hooks/useAuthConfig';
import useIdleTimer from 'hooks/useIdleTimer';
import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { parseAuthResponse } from 'views/pages/authentication/auth-forms/AuthLogin';

const IdleTimerContext = createContext({});

export const IdleTimerProvider = ({ children }) => {
    const navigate = useNavigate();
    useEffect(() => {
        
    }, []);
    const [idleTimerConfig, setIdleTimerConfig] = useState({});

    return (
        <IdleTimerContext.Provider value={{ idleTimerConfig, setIdleTimerConfig }} >
            {children}
        </IdleTimerContext.Provider>
        )
}

export default IdleTimerContext;