/* https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/ */

import React from 'react';
import { FastFieldProps, FieldProps, getIn } from 'formik';
import { TextFieldProps, TextField, MenuItem } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SelectValue } from 'services/shared/select-value';
import { CircularProgress, InputAdornment } from '@material-ui/core';

export interface FormMenuFieldProps {
  menuList: SelectValue[],
  loading?: boolean
}

export const shouldComponentUpdate = (nextProps: any, currentProps: any) => {
  return nextProps.name !== currentProps.name
    || nextProps.required !== currentProps.required
    || nextProps.disabled !== currentProps.disabled
    || nextProps.readOnly !== currentProps.readOnly
    || nextProps.formik.isSubmitting !== currentProps.formik.isSubmitting
    || Object.keys(nextProps).length !== Object.keys(currentProps).length
    || getIn(nextProps.formik.values, currentProps.name) !== getIn(currentProps.formik.values, currentProps.name)
    || getIn(nextProps.formik.errors, currentProps.name) !== getIn(currentProps.formik.errors, currentProps.name)
    || getIn(nextProps.formik.touched, currentProps.name) !== getIn(currentProps.formik.touched, currentProps.name)
    || nextProps.menuList !== currentProps.menuList
    || nextProps.loading !== currentProps.loading;
};

export const FormMenuField: React.FC<FormMenuFieldProps & FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)
  
  const { error, menuList = [], helperText, field, form, loading, ...rest } = props;

  field.value = field.value ?? "";
  // form.
  
  const theme: any = useTheme();

  const [menu, setMenu] = React.useState(props.menuList);

  React.useEffect(() => {
    setMenu(menuList);
  }, [menuList]);

  return (
    <TextField
      select
      variant="outlined"
      margin="normal"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      sx={{ ...theme.ecap.customInput }}
      {...rest}
      {...field}
      onChange={(e) => {if(rest.onChange) { rest.onChange(e);} field.onChange(e); return e;}}
      InputProps={{
        endAdornment: 
          loading? <div style={{paddingRight: 10}}><CircularProgress /></div> : <></>
        
      }}
    
    >
      <MenuItem value={""}></MenuItem>
      {menu.map(item => {
        return <MenuItem key={item.code} value={item.code} sx={{ ...theme.ecap.customInput }}>{item.value}</MenuItem>
      })}
    </TextField>
  )
}
