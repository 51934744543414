import { createContext, useEffect, useState } from 'react';
import config from '../config'
import { parseAuthResponse } from 'views/pages/authentication/auth-forms/AuthLogin';
import { CircularProgress } from '@mui/material';

const AuthConfigContext = createContext({});

export const AuthConfigProvider = ({ children }) => {
     let authConfigState = {};
     useEffect(() =>{
        fetch(config.authZBaseApiUrl + '/api/Authorize/config?appName=' + config.appName)
        .then(res => res.json())
        .then(res => {
            
            let keys = null;
            const host = `https://cognito-idp.${res.authN.region}.amazonaws.com`;
            const keys_url = `${host}/${res.authN.cognitoUserPoolId}/.well-known/jwks.json`;
            
            fetch(keys_url)
                .then((response) => response.json())
                .then((data) => {
                    keys = data.keys;
                    setAuthConfig({keys, ...res})
                });
            
    
        })
    },[])
    const [authConfig, setAuthConfig] = useState(authConfigState);

    return (
        <AuthConfigContext.Provider value={{ authConfig, setAuthConfig }} >
            {authConfig.applicationId ? children : <CircularProgress>Loading Config</CircularProgress>}
        </AuthConfigContext.Provider>
        )
}

export default AuthConfigContext;