import React from 'react';

import Moment from 'react-moment';

import { Link, useLocation } from 'react-router-dom';

// material-ui
import {
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    MenuItem,
    Container,
    Button,
} from '@mui/material';

// assets
import MenuIcon from '@mui/icons-material/Menu';

import pages from 'menu-items/pages';
import useAuth from 'hooks/useAuth';
import useAppSettings from 'hooks/useAppSettings';

const ResponsiveAppBar = () => {
    const location = useLocation();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const currentDate = new Date();
    const {auth} = useAuth();
    const { appSettings, } = useAppSettings();
    const hideNavCodeList = appSettings?.HIDENAVIGATIONLIST?.split(",") || [];
    const pagesApplicable = pages
        .filter(page =>   ((appSettings.HASFFP && page.ffp) || 
                          (appSettings.HASECAP && page.ecap)) && 
                          !hideNavCodeList.includes(page.code) &&
                          (!page.scope 
                                || (typeof page.scope === 'string' 
                                        && auth?.roles?.includes(page.scope) 
                                        && !auth?.agencyScopesToExclude?.includes(page.scope)
                                    )
                                || (typeof page.scope === 'object' 
                                            && page.scope.anyOf 
                                            && page.scope.anyOf.length 
                                            && auth?.roles?.some(authRole => page.scope.anyOf.includes(authRole))
                                            && !auth?.agencyScopesToExclude?.some(authRole => page.scope.anyOf.includes(authRole))
                                    )
                          )
                )

    return (
        <Box sx={{ flexGrow: 1 }}>
        <AppBar style={{ background: auth?.themeJson?.Primary || 'primary' }} position="static">
            <Container maxWidth='100%'>
                <Toolbar variant="dense" disableGutters>
                        {/* Collapsed Menu items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size="medium"
                            aria-label="menu"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {pagesApplicable.map((pages) => {
                                
                                return (
                                    <MenuItem key={pages.id} onClick={handleCloseNavMenu} component={Link} to={pages.url} >
                                        <Typography textAlign="center">{pages.title}</Typography>
                                        {/*{open ? <ExpandLess /> : <ExpandMore />}*/}
                                    </MenuItem>
                                )
                            })}                            
                        </Menu>
                    </Box>
                        {/* Desktop Menu items */}
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        {pagesApplicable.map((pages) => (
                            
                        <Button
                            key={pages.id}
                            sx={{ color: 'white', display: 'block', marginLeft: '5px', borderBottom: pages.url == location.pathname ? 1 : 0 }}
                            component={Link}
                            to={pages.url}
                        >
                            {pages.title}
                        </Button>
                        ))}
                    </Box>                  

                    <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' }  }}>
                        <Moment format="dddd MMMM DD, YYYY">{currentDate}</Moment>
                    </Box>
                </Toolbar>
            </Container>
            </AppBar>
            </Box>
    );
};
export default ResponsiveAppBar;
