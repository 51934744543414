import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import RequireAuth from 'AuthNZ/RequireAuth';
import { ScopeConstants } from 'services/constants/scope-constants';
import SafeSummaryDetail from 'views/pages/ffp/referrals/safe-summary-detail';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
// page routing
const BestMatchPage = Loadable(lazy(() => import('views/pages/best-match')));
const ServiceMatchPage = Loadable(lazy(() => import('views/pages/service-match')));
const ReverseMatchPage = Loadable(lazy(() => import ('views/pages/reverse-match')));
const AdminPage = Loadable(lazy(() => import('views/pages/admin')))
const AwaitingPlacementPage = Loadable(lazy(() => import('views/pages/awaiting-placement')))
const ReportsPage = Loadable(lazy(() => import('views/pages/reports')))
const SearchPage = Loadable(lazy(() => import('views/pages/search')))
const ClientProfile = Loadable(lazy(() => import('views/pages/client-profile')))
const ProviderProfile = Loadable(lazy(() => import('views/pages/provider-profile')))
const Support = Loadable(lazy(() => import('views/pages/support/support.tsx')))
const PlacementReferralsPage = Loadable(lazy(() => import('views/pages/ffp/referrals/placement-referrals.tsx')))
const UnAuthorized = Loadable(lazy(() => import('views/pages/authentication/UnAuthorized.tsx')))
const FamilyProfile = Loadable(lazy(() => import('views/pages/admin/ffp-accounts/family-profile')))

// ==============================|| MAIN ROUTING ||============================== //
//Routes are protected by the "RequireAuth" route that can be found in the AuthNZ folder.
//This handles all the access needs of any individual route to protect them both by authentication
//but also authorization.
const MainRoutes = {
    element: <RequireAuth />,
    children: [{
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                path: '/dashboard/default',
                element: <DashboardDefault />
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SAVEREFERRAL]}} />,
                children: [
                    {
                        path: '/Placement/Referrals',
                        element: <PlacementReferralsPage />
                    },
                    {
                        path: '/Placement/Referrals/:safeSummaryId',
                        element: <SafeSummaryDetail />
                    },
                ]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.ADMIN_LIST_USERS, ScopeConstants.ADMIN_LIST_AGENCIES, ScopeConstants.ADMIN_DATA_ALERTS, ScopeConstants.FFP_GETALLREGISTRATIONS]}} />,
                children: [{
                    path: '/Admin',
                    element: <AdminPage />
                }]     
                // path: '/Admin',
                // element: <AdminPage />           
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.AWAITINGPLACEMENTLIST, ScopeConstants.RECENTLYREMOVEDAWAITINGPLACEMENTLIST]}} />,
                children: [{
                    path: '/AwaitingPlacement',
                    element: <AwaitingPlacementPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.RPTCLCALLLOG, ScopeConstants.RPTPROVCALLLOG, ScopeConstants.RPTPLMNTSBYDT, ScopeConstants.RPTPLREQS, ScopeConstants.RPTREMOVEDPLREQS, ScopeConstants.RPTPLMATCHAUDIT, ScopeConstants.RPTPROVPROF, ScopeConstants.RPTCLNTASSESS]}} />,
                children: [{
                    path: '/Reports',
                    element: <ReportsPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.BESTMATCH} />,
                children: [{
                    path: '/BestMatchPage',
                    element: <BestMatchPage />
                }]          
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.BESTMATCH} />,
                children: [{
                    path: '/ServiceMatchPage',
                    element: <ServiceMatchPage />
                }]          
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.REVERSEMATCH} />,
                children: [{
                    path: '/ReverseMatchPage/:providerId/:matchType',
                    element: <ReverseMatchPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SEARCHPROVIDER, ScopeConstants.SEARCHCLIENT]}} />,
                children: [{
                    path: '/Search',
                    element: <SearchPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READCLIENT} />,
                children: [{
                    path: '/ClientProfile',
                    element: <ClientProfile />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READPROVIDER} />,
                children: [{
                    path: '/Provider/Profile/:providerId',
                    element: <ProviderProfile />
                }]
            }, 
            {
                element: <RequireAuth allowedRoles={ScopeConstants.GETFAMILYPROFILE} />,
                children: [{
                    path: '/Family/Profile/:familyId',
                    element: <FamilyProfile />
                }]
            },  
            {
                path: '/support',
                element: <Support />
            },  
            {
                path: '/Unauthorized',
                element: <UnAuthorized />
            },
            
            {
                // commented as we are auto generating a route while displaying pdf.
                // will figure out a way and update in upcoming PR.
                // path: '*',
                // element: <DashboardDefault />
            }
        ]
    }]
};

export default MainRoutes;

export function getMainRoutesBy(appSettings) {
    const hasEcap = appSettings?.HASECAP
    const hasFfp = appSettings?.HASFFP
    const MainRoutes = {
        element: <RequireAuth />,
        children: [{
            path: '/',
            element: <MainLayout />,
            children: [
                {
                    path: '/',
                    element: <DashboardDefault />
                },
                {
                    element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.LISTREFERRAL]}} />,
                    children: [
                        {
                            path: '/Placement/Referrals',
                            element: <PlacementReferralsPage />
                        },
                        {
                            path: '/Placement/Referrals/:safeSummaryId',
                            element: <SafeSummaryDetail />
                        },
                    ]
                },
                {
                    element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.ADMIN_LIST_USERS, ScopeConstants.ADMIN_LIST_AGENCIES, ScopeConstants.ADMIN_DATA_ALERTS, ScopeConstants.FFP_GETALLREGISTRATIONS]}} />,
                    children: [{
                        path: '/Admin',
                        element: <AdminPage />
                    }]          
                },
                {
                    element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.AWAITINGPLACEMENTLIST, ScopeConstants.RECENTLYREMOVEDAWAITINGPLACEMENTLIST]}} />,
                    children: [{
                        path: '/AwaitingPlacement',
                        element: <AwaitingPlacementPage />
                    }]
                },
                {
                    element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.RPTCLCALLLOG, ScopeConstants.RPTPROVCALLLOG, ScopeConstants.RPTPLMNTSBYDT, ScopeConstants.RPTPLREQS, ScopeConstants.RPTREMOVEDPLREQS, ScopeConstants.RPTPLMATCHAUDIT, ScopeConstants.RPTPROVPROF, ScopeConstants.RPTCLNTASSESS]}} />,
                    children: [{
                        path: '/Reports',
                        element: <ReportsPage />
                    }]
                },
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.BESTMATCH} />,
                    children: [{
                        path: '/BestMatchPage',
                        element: <BestMatchPage />
                    }]          
                },
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.GETSERVICEMATCHCLIENT} />,
                    children: [{
                        path: '/ServiceMatchPage',
                        element: <ServiceMatchPage />
                    }]          
                },
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.REVERSEMATCH} />,
                    children: [{
                        path: '/ReverseMatchPage/:providerId/:matchType',
                        element: <ReverseMatchPage />
                    }]
                },
                {
                    element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SEARCHPROVIDER, ScopeConstants.SEARCHCLIENT]}} />,
                    children: [{
                        path: '/Search',
                        element: <SearchPage />
                    }]
                },
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.READCLIENT} />,
                    children: [{
                        path: '/ClientProfile',
                        element: <ClientProfile />
                    }]
                },
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.READPROVIDER} />,
                    children: [{
                        path: '/Provider/Profile/:providerId',
                        element: <ProviderProfile />
                    }]
                }, 
                {
                    element: <RequireAuth allowedRoles={ScopeConstants.GETFAMILYPROFILE} />,
                    children: [{
                        path: '/Family/Profile/:familyId',
                        element: <FamilyProfile />
                    }]
                },  
                {
                    path: '/support',
                    element: <Support />
                },  
                {
                    path: '/Unauthorized',
                    element: <UnAuthorized />
                },
            ]
        }]
    };  

    return MainRoutes;
}

export const EcapRoutes = {
    element: <RequireAuth />,
    children: [{
        path: '/',
        element: <MainLayout />,
        children: [
            {
                path: '/',
                element: <DashboardDefault />
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.AWAITINGPLACEMENTLIST, ScopeConstants.RECENTLYREMOVEDAWAITINGPLACEMENTLIST]}} />,
                children: [{
                    path: '/AwaitingPlacement',
                    element: <AwaitingPlacementPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.RPTCLCALLLOG, ScopeConstants.RPTPROVCALLLOG, ScopeConstants.RPTPLMNTSBYDT, ScopeConstants.RPTPLREQS, ScopeConstants.RPTREMOVEDPLREQS, ScopeConstants.RPTPLMATCHAUDIT, ScopeConstants.RPTPROVPROF, ScopeConstants.RPTCLNTASSESS]}} />,
                children: [{
                    path: '/Reports',
                    element: <ReportsPage />
                }]
            },
            {
                path: '/BestMatchPage',
                element: <BestMatchPage />
            },
            {
                path: '/ReverseMatchPage/:providerId/:matchType',
                element: <ReverseMatchPage />
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SEARCHPROVIDER, ScopeConstants.SEARCHCLIENT]}} />,
                children: [{
                    path: '/Search',
                    element: <SearchPage />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READCLIENT} />,
                children: [{
                    path: '/ClientProfile',
                    element: <ClientProfile />
                }]
            },
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READPROVIDER} />,
                children: [{
                    path: '/Provider/Profile/:providerId',
                    element: <ProviderProfile />
                }]
            }, 
            {
                element: <RequireAuth allowedRoles={ScopeConstants.READPROVIDER} />,
                children: [{
                    path: '/Family/Profile/:familyId',
                    element: <FamilyProfile />
                }]
            },
        ]
    }]
};


export const FfpRoutes = {
    element: <RequireAuth />,
    children: [{
        path: '/',
        element: <MainLayout />,
        children: [
            
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.SAVEREFERRAL]}} />,
                children: [
                    {
                        path: '/Placement/Referrals',
                        element: <PlacementReferralsPage />
                    },
                    {
                        path: '/Placement/Referrals/:safeSummaryId',
                        element: <SafeSummaryDetail />
                    },
                ]
            },
            {
                element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.ADMIN_LIST_USERS, ScopeConstants.ADMIN_LIST_AGENCIES, ScopeConstants.ADMIN_DATA_ALERTS, ScopeConstants.FFP_GETALLREGISTRATIONS]}} />,
                children: [{
                    path: '/Admin',
                    element: <AdminPage />
                }]     
                // path: '/Admin',
                // element: <AdminPage />           
            },
        ]
    }]
};

export const CommonRoutes = {
    element: <RequireAuth />,
    children: [{
        path: '/',
        element: <MainLayout />,
        children: [
    {
        element: <RequireAuth allowedRoles={{anyOf: [ScopeConstants.ADMIN_LIST_USERS, ScopeConstants.ADMIN_LIST_AGENCIES, ScopeConstants.ADMIN_DATA_ALERTS, ScopeConstants.FFP_GETALLREGISTRATIONS]}} />,
        children: [{
            path: '/Admin',
            element: <AdminPage />
        }]     
        // path: '/Admin',
        // element: <AdminPage />           
    },
    {
        path: '/support',
        element: <Support />
    },  
    {
        path: '/Unauthorized',
        element: <UnAuthorized />
    }
]}]
};