/* https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/ */

import React from 'react';
import { FieldProps, getIn } from 'formik';
import { TextFieldProps, TextField } from '@mui/material';
import { useTheme } from '@mui/material/styles';

/**
 * Material TextField Component with Formik Support including Errors.
 * Intended to be specified via the `component` prop in a Formik <Field> or <FastField> component.
 * Material-UI specific props are passed through.
 */
export const FormTextField: React.FC<FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)

  const { error, helperText, field, form, ...rest } = props

  const theme: any = useTheme();

  return (
    <TextField
      variant="outlined"
      margin="normal"
      error={error ?? Boolean(isTouched && errorMessage)}
      helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
      sx={{ ...theme.ecap.customInput }}
      {...rest}
      {...field}
    />
  )
}
