/* https://firxworx.com/blog/coding/react/integrating-formik-with-react-material-ui-and-typescript/ */

import React, { useEffect, useState } from 'react';
import { Field, FieldProps, getIn } from 'formik';
import { TextFieldProps, TextField, CircularProgress, Checkbox, ListItemText, Typography, Paper, Box, FormControlLabel, Divider } from '@mui/material';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { useTheme } from '@mui/material/styles';
import { SelectValue } from 'services/shared/select-value';
import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export interface FormMenuMultipleFieldProps {
  menuList: SelectValue[]
}

/*
Created Custom paper as per 
https://github.com/mui/material-ui/issues/38049
https://github.com/mui/material-ui/issues/31073
*/
const CustomPaper = ({ children, addSelectAll, selectAll, setSelectAll, onChange, ...paperProps }) => (<Paper {...paperProps}>
  {addSelectAll ? (<><Box
                  onMouseDown={(e) => e.preventDefault()} // prevent blur
                  pl={1.5}
                  py={0.5}
                >
                  <FormControlLabel
                    label="Select all"
                    control={
                      <Checkbox id="select-all-checkbox" checked={selectAll}
                      onChange={ (event: React.ChangeEvent<HTMLInputElement>) => {
                        if(onChange) {
                          onChange(event)
                        }
                        
                          
                        }
                      } />
                    }
                  />
                </Box>
                <Divider /></>) : <></>}
                {children}
</Paper>);

export const FormMenuMultipleField: React.FC<FormMenuMultipleFieldProps & FieldProps & TextFieldProps> = props => {
  const isTouched = getIn(props.form.touched, props.field.name)
  const errorMessage = getIn(props.form.errors, props.field.name)
  const [selectAll, setSelectAll] = useState<boolean>(false);

  const icon = <CheckBoxOutlineBlankOutlined fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const { error, menuList = [], onChange, addSelectAll, helperText, field, loading, form, ...rest} = props;
  const formFieldValue  = form.values[field.name] || [];
  const value = (typeof formFieldValue === 'string' || formFieldValue instanceof String ? [] : formFieldValue)
  const handleToggleSelectAll = () => {
    form.setFieldValue(field.name,  selectAll ? [] : menuList);
    
    setSelectAll((prev) => !prev);
  };

  let newList: any[] = menuList.map((menu: SelectValue) => {
    return { id: menu.code, value: menu.value };
  });
  
  
  const theme: any = useTheme();
  const filterOptions = createFilterOptions({
    matchFrom: 'any',
    // limit: 100,
  });

  return (
    <Autocomplete
      multiple
      key={selectAll ? 'abcd' : 'efgh'}
      filterOptions={filterOptions}
      options={newList}
      value={value}
      // defaultValue={form.values[field.name]}
      getOptionLabel={option => option.value}
      sx={{ ...theme.ecap.customInput }}
      style={{ marginTop: '16px' }}
      isOptionEqualToValue={(option, value) => (option.id == value.id) || selectAll}
      openOnFocus
      disableCloseOnSelect={true}
      onChange={(e, value) => {
        form.setFieldValue(field.name, value);
        setSelectAll(false);
        if(props.onSelectionChange) {
          props.onSelectionChange(e, value)
        }
      }}
      renderInput={params => (
        <Field 
          label={rest?.label}
          component={TextField} 
          {...params} variant="outlined" 
          error={error ?? Boolean(isTouched && errorMessage)} 
          {...rest}
          {...field}
          helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
          sx={{ ...theme.ecap.customInput }}
          InputProps={{
            ...params.InputProps,
            sx:{ ...theme.ecap.customInput },
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }} 
          />
        // <TextField 
        // key={'abcd' + selectAll}
        //   label={rest?.label}
        //   {...params} variant="outlined" 
        //   error={error ?? Boolean(isTouched && errorMessage)}
        //   {...rest}
        //   {...field}
        //   helperText={helperText ?? ((isTouched && errorMessage) ? errorMessage : undefined)}
        //   sx={{ ...theme.ecap.customInput }}
        //   InputProps={{
        //     ...params.InputProps,
        //     sx:{ ...theme.ecap.customInput },
        //     endAdornment: (
        //       <React.Fragment>
        //         {loading ? <CircularProgress color="inherit" size={20} /> : null}
        //         {params.InputProps.endAdornment}
        //       </React.Fragment>
        //     ),
        //   }}
        //    />
      )}
      renderOption={(props, option, { selected }) => (
        <li {...props} key={option.code}>
          <Checkbox
            icon={icon}
            checkedIcon={checkedIcon}
            style={{ marginRight: 8 }}
            checked={selected}
            sx={{ ...theme.ecap.customInput }}
          />
            <Typography style={{ fontSize: '20px', fontWeight: 400 }}>{option.value}</Typography>
        </li>
      )}
      PaperComponent={CustomPaper}
      componentsProps={{ 
        paper: { 
          selectAll:selectAll, 
          setSelectAll: setSelectAll, 
          onChange:(event) => {
            form.setFieldValue(field.name,  event.target.checked ? newList: undefined);
            setSelectAll(prev => !prev);
          },
          addSelectAll: addSelectAll
        } 
      }}
          
    />
  )
}
