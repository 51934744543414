import PropTypes from 'prop-types';
import { forwardRef } from 'react';

// material-ui
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useTheme } from '@mui/material/styles';
// constant
// const headerSX = {
//     '& .MuiCardHeader-action': { mr: 0 }
// };

export interface IMainCardProps {
    border?: boolean,
    boxShadow?: boolean,
    children?: ReactNode,
    content?: boolean,
    contentClass?: string,
    contentSX?: object,
    darkTitle?: boolean,
    secondary?: ReactNode | string | object,
    shadow?: string,
    sx?: object,
    title?: ReactNode | string | object,
    headerSX?: object,
    others?: any,
};


// ==============================|| CUSTOM MAIN CARD ||============================== //

const MainCard = forwardRef(
    (
        props: IMainCardProps,
        ref: React.Ref<HTMLDivElement>
    ) => {
        
        const theme = useTheme();

        return (
            <Card
                ref={ref}
                {...props.others}
                sx={{                    
                    borderTop: '3px solid',
                    borderTopColor: theme.ecap.secondary,
                    bgcolor: 'background.paper',
                    ':hover': {
                        boxShadow: props.boxShadow ? props.shadow || '0 2px 14px 0 rgb(32 40 45 / 8%)' : 'inherit'
                    },
                    ...props.sx
                }}
            >
                {/* card header and action */}
                {!props.darkTitle && props.title && <CardHeader sx={props.headerSX} title={props.title} action={props.secondary} />}
                {props.darkTitle && props.title && (
                    <CardHeader sx={props.headerSX} title={<Typography variant="h3">{props.title}</Typography>} action={props.secondary} />
                )}

                {/* content & header divider */}
                {props.title && <Divider />}

                {/* card content */}
                {props.content && (
                    <CardContent sx={props.contentSX} className={props.contentClass}>
                        {props.children}
                    </CardContent>
                )}
                {!props.content && props.children}
            </Card>
        );
    }
);

MainCard.defaultProps = {
  headerSX: {'& .MuiCardHeader-action': { mr: 0 }, '& .MuiCardHeader-title': { textAlign: 'center'}},
}

// MainCard.propTypes = {
//     border: PropTypes.bool,
//     boxShadow: PropTypes.bool,
//     children: PropTypes.node,
//     content: PropTypes.bool,
//     contentClass: PropTypes.string,
//     contentSX: PropTypes.object,
//     darkTitle: PropTypes.bool,
//     secondary: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object]),
//     shadow: PropTypes.string,
//     sx: PropTypes.object,
//     title: PropTypes.oneOfType([PropTypes.node, PropTypes.string, PropTypes.object])
// };

export default MainCard;
